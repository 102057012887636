import React, { useState, useEffect, createContext, useContext } from 'react'
import Api from 'utils/api'
import { useAuth } from './auth'
import Loader from 'components/loader'

const initAgencyContextPropsState = {
  loading: false,
  agency: undefined,
  setAgency: () => {},
  getAgency: async () => {},
  style: {
    cover: 'https://placehold.co/10x10',
    logo: 'https://placehold.co/10x10',
    background: {
      page_bg_color: '#D4E3D6',
      card_bg_color: '#1A73E8',
      card_button_bg_color: '#C4E3D6',
      card_button_text_color: '#C4E3D6',
    },
    text: {
      top_header: '#ffffff',
      card: '#ffffff',
      headings: '#ffffff',
      active_tabs_icon: '#847171',
      active_text_icon: '#D04200',
      tabs_text: '#847171',
      tabs_icon: '#847171',
      // button_text: '#345171',
    },
  },
}

const AgencyContext = createContext(initAgencyContextPropsState)

const useAgency = () => {
  return useContext(AgencyContext)
}

const AgencyProvider = ({ children }) => {
  // const { currentUser } = useAuth()
  const [loading, setLoading] = useState(true)
  const [agency, setAgency] = useState([])

  const getAgency = async () => {
    const { response } = await Api(
      '/agency/domain/scan_app?domain=' + window.location.host,
      'get'
    )
    setLoading(false)
    if (response) setAgency(response.data)
    else setAgency({})
  }

  const onLoad = () => {
    getAgency()
  }

  useEffect(onLoad, [])

  const style = {
    cover: agency?.scanner_config?.cover,
    logo: agency?.scanner_config?.logo,
    background: {
      page_bg_color: agency?.scanner_config?.page_bg_color,
      card_bg_color: agency?.scanner_config?.card_bg_color,
      card_button_bg_color: agency?.scanner_config?.card_button_bg_color,
      card_button_text_color: agency?.scanner_config?.card_button_text_color,
    },
    text: {
      button_text: agency?.scanner_config?.card_button_text_color,
    },
  }

  return (
    <AgencyContext.Provider
      value={{
        loading,
        agency,
        setAgency,
        getAgency,
        style,
      }}
    >
      {loading ? <Loader /> : children}
    </AgencyContext.Provider>
  )
}

export { AgencyProvider, useAgency }
