import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import ComponentLoader from './component-loader'
import AppLayout from 'screens/AppLayout'

const Profile = ComponentLoader(lazy(() => import('screens/Profile/profile')))

const Home = ComponentLoader(lazy(() => import('screens/Home/home')))

const PublicRoutes = [
  {
    path: '',
    element: <AppLayout />,
    children: [
      {
        path: '',
        index: true,
        element: <Home />,
      },
      {
        path: 'profile',
        element: (
          <div className='max-w-3xl mx-auto'>
            <Profile />
          </div>
        ),
      },
      {
        path: '*',
        element: <Navigate to={'/'} />,
      },
      {
        path: '',
        element: <Navigate to={'/'} />,
      },
    ],
  },
]

export default PublicRoutes
