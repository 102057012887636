import Routes from 'routing/routes'
import { useRoutes } from 'react-router-dom'
import './App.css'
import { AuthInit } from 'context/auth'
import { LocationInit } from 'context/location'
import { useAgency } from 'context/agency'

function App() {
  const { style } = useAgency()
  const routes = useRoutes(Routes())
  return (
    <div
      // className='h-[100vh] overflow-y-auto '
      style={{
        backgroundColor: style.background.page_bg_color,
      }}
    >
      <AuthInit>
        <LocationInit>{routes}</LocationInit>
      </AuthInit>
    </div>
  )
}

export default App
