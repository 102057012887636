import React, { lazy } from 'react'
import ComponentLoader from './component-loader'
import { Navigate } from 'react-router-dom'
import UnAuthorizeLayout from 'screens/unauthorize/unauthorize-layout'

const InvalidAccess = ComponentLoader(
  lazy(() => import('screens/unauthorize/invalid-access'))
)

const Profile = ComponentLoader(lazy(() => import('screens/Profile/profile')))

const InvalidAccessRoutes = [
  {
    path: '',
    element: <UnAuthorizeLayout />,
    children: [
      {
        path: '/unauthorize',
        index: true,
        element: <InvalidAccess />,
      },
      {
        path: '/profile',
        index: true,
        element: (
          <div className='max-w-3xl mx-auto'>
            <Profile />
          </div>
        ),
      },
      {
        path: '',
        index: true,
        element: <Navigate replace to={'/unauthorize'} />,
      },
      {
        path: '*',
        index: true,
        element: <Navigate replace to={'/unauthorize'} />,
      },
    ],
  },
]

export default InvalidAccessRoutes
