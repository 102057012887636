import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-toastify/dist/ReactToastify.css'
import 'cropperjs/dist/cropper.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { AgencyProvider } from 'context/agency'
import { setupAxios } from 'utils/auth-helper'
import { AuthProvider } from 'context/auth'
import { LocationProvider } from 'context/location'
import { ToastContainer } from 'react-toastify'

setupAxios()
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AuthProvider>
      <AgencyProvider>
        <LocationProvider>
          <App />
        </LocationProvider>
      </AgencyProvider>
    </AuthProvider>
    <ToastContainer />
  </BrowserRouter>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
