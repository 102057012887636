import Header from 'components/Header/header-auth'
import { useAgency } from 'context/agency'
import React from 'react'
import { Outlet } from 'react-router-dom'

function AuthLayout() {
  const { style } = useAgency()
  return (
    <div
      className='h-[100vh] overflow-y-auto'
      style={{
        backgroundColor: style.background.page,
      }}
    >
      <Header />
      <main className='w-full px-4'>
        <Outlet />
      </main>
    </div>
  )
}

export default AuthLayout
