import { Environment } from '../enums'

const env = {
  // API_URL: 'http://localhost:8082/api',
  // API_URL: 'http://192.168.18.26:8082/api',
  API_URL: 'https://dev.api.walletcampaigns.com/api'
}

if (process.env.REACT_APP_ENV === Environment.STAGING) {
  env.API_URL = window.location.hostname.includes('dev.scan.wallet')
    ? 'https://dev.api.walletcampaigns.com/api'
    : `${window.location.origin}/server/api`
}

if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env.API_URL = window.location.hostname.includes('scan.wallet')
    ? 'https://api.walletcampaigns.com/api'
    : `${window.location.origin}/server/api`
}

export default env
